'use client';

import { ReactNode, useEffect, useMemo } from 'react';
import { Flex } from '@chakra-ui/react';
import { Roboto, Roboto_Slab as RobotoSlab } from 'next/font/google';
import dynamic from 'next/dynamic';

import { usePathname } from 'next/navigation';
import { MobileNavigationProvider } from 'context/mobileNavigation';
import { Link, Img } from 'components';
import { Paths } from 'utils/paths';
import { useResponsiveness } from 'hooks/useResponsiveness';
import { doesRouteMatch } from 'utils/helpers';
import { Footer } from './components/Footer';
import { LayoutContextProvider, useLayout } from './useLayout';

const DesktopNavigation = dynamic(
  () => import('./components/DesktopNavigation/DesktopNavigation'),
  { ssr: false },
);
const MobileNavigation = dynamic(
  () => import('./components/MobileNavigation/MobileNavigation'),
  { ssr: false },
);

const roboto = Roboto({
  weight: ['100', '300', '400', '500', '700'],
  subsets: ['latin'],
  style: ['normal', 'italic'],
  display: 'swap',
});

const robotoSlab = RobotoSlab({
  weight: ['100', '200', '300', '400', '500', '600', '700'],
  subsets: ['latin'],
  style: ['normal'],
  display: 'swap',
});

interface Props {
  children: ReactNode;
  content: LayoutContent;
}

const LayoutBase = (props: Props) => {
  const { content, children } = props;

  const { changeLayoutContent } = useLayout();

  const { isMobile } = useResponsiveness();

  useEffect(() => {
    changeLayoutContent(content);
  }, []);

  const pathname = usePathname();

  const isDesktopNavigationEnabled = useMemo(() => {
    if (isMobile) return false;

    const disableAtPaths = [Paths.SEARCH];

    const isDisabled = disableAtPaths.some(path => {
      return doesRouteMatch({ signature: path, route: pathname });
    });

    return !isDisabled;
  }, [isMobile, pathname]);

  return (
    <>
      <style jsx global>
        {`
          :root {
            --font-roboto-slab: ${robotoSlab.style.fontFamily}, serif;
            --font-roboto: ${roboto.style.fontFamily}, sans-serif;
          }
        `}
      </style>

      <Flex direction="column" h="full" w="100vw" justify="space-between">
        <MobileNavigationProvider>
          {isDesktopNavigationEnabled && <DesktopNavigation />}

          <Flex
            direction="column"
            h="full"
            w="full"
            pt={isDesktopNavigationEnabled ? [0, 0, '5rem'] : undefined}
            pos="relative"
          >
            {isMobile && (
              <Link
                href={Paths.HOME}
                alignSelf="flex-start"
                pos="absolute"
                top="1.5rem"
                left="1.5rem"
                zIndex={1}
              >
                <Img
                  src={content.topbar.zmyleLogoUrl}
                  alt={content.topbar.zmyle}
                  filter="brightness(0) invert(1)"
                  height="1rem"
                  width="9.25rem"
                />
              </Link>
            )}

            {children}

            <Footer />
          </Flex>

          {isMobile && <MobileNavigation />}
        </MobileNavigationProvider>
      </Flex>
    </>
  );
};

interface LayoutProps extends Props {
  defaultSEO: Record<keyof SEO, string>;
}

export const Layout = (props: LayoutProps) => {
  const { defaultSEO } = props;

  return (
    <LayoutContextProvider defaultSEO={defaultSEO}>
      <LayoutBase {...props} />
    </LayoutContextProvider>
  );
};
